<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end> 
                        <router-link to="/companies/company-branches">
                            <Button v-tooltip.top="'Manage Branches'" label="Manage Branches" icon="pi pi-arrow-up-right" class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link>
                    </template> 
				</Toolbar> 
                
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-tooltip.top="'Add New'" label="" @click="loadForm" icon="pi pi-plus" class="p-button-raised p-button-md p-button-primary mr-2" /> 
                        <Button v-tooltip.top="'Refresh'" label="" icon="pi pi-refresh" class="p-button-raised p-button-md mr-2 p-button-success"  @click="getRecords"/> 
                        <Button v-tooltip.top="'Export'" icon="pi pi-external-link" class="p-button-raised p-button-md p-button-warning mr-2" label="" @click="exportCSV($event)" />
                        <Button v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" label="" class="p-button-raised p-button-md p-button-info  mr-2" @click="clearFilter()"/> 
                        <Button v-tooltip.top="'Delete Selected'" label="" icon="pi pi-trash" class="p-button-raised p-button-md p-button-danger mr-2" @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
				</Toolbar> 
				<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','email','phone','postal_address','website','sender_name','sender_email','language','country_name','state_name','city_name','date_added','date_modification','added_by','modified_by','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'name' && data.logo" :alt="data.name" :src="filesUrl+'users/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'name' && !data.logo" :alt="data.name" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.parent_company_name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'name'">{{data.name}}</span>
                            <span v-if="col.field == 'email'">{{data.email}}</span> 
                            <span v-if="col.field == 'company'">{{data.company}}</span> 
                            <span v-if="col.field == 'roles_name'">{{data.roles_name}}</span> 
                            <span v-else-if="col.field == 'description'">{{shortStr(data.description,0,50)}}</span>
                            <span v-else-if="col.field == 'date_added'">{{formatDate(data.date_added)}}</span>
                            <span v-else-if="col.field == 'added_by'">{{ data.added_by }}</span>
                            <span v-else-if="col.field == 'modified_by'">{{ data.modified_by}}</span>
                            <span v-else-if="col.field == 'date_modification'">{{formatDate(data.date_modification)}}</span>
                            <span v-else-if="col.field == 'status'"> 
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'" icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'" :value="data.status"></Tag>
                            </span>
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-if="col.field == 'email'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by email"/>
                            </span> 
                            <span v-if="col.field == 'company'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company"/>
                            </span> 
                            <span v-if="col.field == 'roles_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by roles"/>
                            </span> 
                            <span v-else-if="col.field == 'description'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by about"/>
                            </span> 
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-if="col.field == 'modified_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span> 
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Added"/>
                            </span>
                            <span v-else-if="col.field == 'date_modification'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Modification"/>
                            </span> 
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>

        <Toast />
        <ConfirmDialog group="dialog" />
        <Sidebar v-model:visible="showForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage User</h3> 
                        <p>You can add or update user here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Email</label>
                                    <InputText id="email" placeholder="Email" v-model="form.email"  type="email" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="email" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1" type="number" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Roles</label>
                                    <MultiSelect :filter="true" v-model="form.roles" :options="roles" optionLabel="name" optionValue="id" placeholder="Select Roles" display="chip" class="full-width" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>About this user</label>
                                    <Textarea placeholder="Role Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div> 
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div> 
                                <Panel v-if="profilePic" class="mb-3" header="Current Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg"> 
                                        <div>
                                            <img alt="Profile Picture" :src="profilePic" >
                                        </div>
                                        <div>
                                           <ConfirmPopup group="popup"></ConfirmPopup>
                                           <Button ref="popup" @click="removeProfilePic($event)" icon="pi pi-times" label="Remove" class="p-button-danger p-button-raised p-button-md" style="margin-left: .5em" />
                                        </div>   
                                    </div>
                                </Panel>
                                <Panel class="mb-3" header="Change Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <FileUpload name="profilePic" :showUploadButton="false" :customUpload="true" @select="myUploader" :multiple="false" accept="image/*" :maxFileSize="1000000">
                                            <template #empty>
                                                <p>Drag and drop files here to upload.</p>
                                            </template>
                                        </FileUpload> 
                                    </div> 
                                </Panel> 
                                <Panel class="mb-3" header="Additional Permissions" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                         <Tree :filter="true" :value="availablePermissions" selectionMode="checkbox" v-model:selectionKeys="form.additionalPermissions"></Tree>    
                                    </div> 
                                </Panel> 
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                        <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                        <Button type="button" label="Cancel" icon="pi pi-times" class="p-button-raised p-button-lg p-button-warning mr-2" @click="closeForm"/>
                    </div>
                </div> 
            </form>
        </Sidebar>
    </div>
</template>
<script>
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return { 
                
                form: new this.Form({ 
                    id: '', 
                    name: '',
                    email: '',
                    profile_pic: '',
                    phone: '',
                    roles:[],
                    status:'',
                    description: '', 
                    additionalPermissions:'',
                }),
                actions: [ 
                    {
						label: 'Update',
						icon: 'pi pi-refresh',
                        command: () => {
                            this.getClickedRecord('update');
                        }
					},
					{
						label: 'Delete',
						icon: 'pi pi-times',
                        command: () => {
                            this.getClickedRecord('delete');
                        }
					} 
				],
                module_name: 'Company Profile',
                availablePermissions:null,
                profilePic:false,
                isSaving:false,
				roles: null,  
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                saveAndClose:false, 
			}
		},   
		created() {   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'name', header: 'Name'}, 
                {field: 'email', header: 'Email'},
                {field: 'phone', header: 'Phone'}, 
                {field: 'company', header: 'Company'}, 
                {field: 'roles_name', header: 'Roles'}, 
                {field: 'description', header: 'Description'},
                {field: 'date_added', header: 'Date Added'},
                {field: 'added_by', header: 'Created By'},
                {field: 'date_added', header: 'Date Added'},
                {field: 'modified_by', header: 'Last Modified By'},
                {field: 'date_modification', header: 'Date Modification'},
                {field: 'status', header: 'Status'},
                
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 
			this.getRecords(); 
			this.getRoles(); 
            this.getUserPermissions();
		},
		methods: {   
            getUserPermissions(){
                var Vthis = this; 
                Vthis.axios.post('getCurrentUserPermissions')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.availablePermissions = response.data;
                    } 
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            },
            removeProfilePic(event){
                var Vthis = this; 
                this.$confirm.require({
                    target: event.currentTarget,
					group: 'popup',
                    header: 'Confirmation',
                    message: 'Are you sure you want to remove?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {  
                        Vthis.axios.post('deleteUserProfilePic',{id:Vthis.selectedRecordId})
                        .then( (response) => {
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){    
                                Vthis.profilePic = false;
                                Vthis.showMessage('Profile picture removed successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            // Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            myUploader(event){   
                this.form.profile_pic = event.files;
            }, 
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveUser', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(!this.selectedRecordId){
                            this.resetForm();
                        } 
                        this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success'); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            },
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.viewRecord();
                }  
                else if(action == 'update'){
                    this.updateRecord();
                }
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){
                this.resetForm();
                this.showForm = true;
            },
            closeForm(){
                this.showForm = false;
            },
            getRoles(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllRoles')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.roles = response.data;   
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.loading = false; 
                })  
            },
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllUsers')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            updateRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllUsers',{id:this.selectedRecordId})
                .then( (response) => {    
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                        Vthis.form.fill(response.data[0]);
                        Vthis.profilePic = false;
                        if(response.data[0].profile_pic){
                            Vthis.profilePic = Vthis.filesUrl+"users/"+response.data[0].profile_pic;
                        }
                        
                        // console.log(Vthis.profilePic);
                        if(response.data[0].role_ids){
                            var selectedRoles = response.data[0].role_ids.split(','); 
                            var frr = [];
                            this.roles.forEach( (role) => {  
                                if(selectedRoles.includes(role.id.toString())){ 
                                    frr.push(role.id);
                                } 
                            });
                            Vthis.form.roles = frr;
                        } 
                        Vthis.showForm = true;
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.loading = false;
                })  
            },
            viewRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllUsers',{id:this.selectedRecordId})
                .then( (response) => {  
                    this.printResponseResult(response,false);  
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        } 
                        
                        let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        filterdRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        })  

                        Vthis.axios.post('deleteUser',{id:DeleteAbleRecords})
                        .then( (response) => {  
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){  
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'email': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'phone': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'roles_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'modified_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                    'description': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'date_modification': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
					'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			},
            isRTL() {
				return this.$appState.RTL;
			}
			 
			 
			 
		}
}
</script>
 
